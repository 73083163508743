import { mapActions, mapGetters } from 'vuex';

// const PERIOD7 = 604800; // (60 * 60 * 24 * 7 - дней
// const PERIOD365 = 31536000; // 60 * 60 * 24 * 365 - год

import OrderPackage from './OrderPackage/index.vue';
import PromoBRK from './PromoBRK/index.vue';
import PromoBRK2 from './PromoBRK2/index.vue';
import PromoAPP from './PromoAPP/index.vue';
import BannerBoldness from './BannerBoldness/index.vue';
import PromoFreePublication from './PromoFreePublication/index.vue';
import RegionPublishFree from './RegionPublishFree/index.vue';
import FrontlineRegions from './FrontlineRegions/index.vue';
import BenefitTops from './BenefitTops/index.vue';
import CarOwner from './CarOwner/index.vue';
import WebinarForBusiness from './WebinarForBusiness/index.vue';
import VerificationCenter from './VerificationCenter/index.vue';
import ElectricCars from './ElectricCars/index.vue';
import NAInsights from './NAInsights/index.vue';
import BirthdayAutoRia from './BirthdayAutoRia/index.vue';
import IndependenceDay from './IndependenceDay/index.vue';
import CabinetDriver from './CabinetDriver/index.vue';
import Skofka from './Skofka/index.vue';
import MotoringDay from './MotoringDay/index.vue';
import Autoleader from './Autoleader/index.vue';
import BlackFridayBuyer from './BlackFridayBuyer/index.vue';
import BlackFridayDay1Sellers from './BlackFridayDay1Sellers/index.vue';
import BlackFridayDay2Sellers from './BlackFridayDay2Sellers/index.vue';
import BlackFridayDay3Sellers from './BlackFridayDay3Sellers/index.vue';
import NewTeaser from './NewTeaser/index.vue';
import ResultsYear from './ResultsYear/index.vue';
import Tops23 from './Tops23/index.vue';
import TopsC2C from './TopsC2C/index.vue';
import TopsSMB from './TopsSMB/index.vue';
import TopsB2C1 from './TopsB2C1/index.vue';
import TopsB2C2 from './TopsB2C2/index.vue';
import Tops28 from './Tops28/index.vue';
import PromoFinancing from './PromoFinancing/index.vue';
import NationalHolidayBuyers from './NationalHolidayBuyers/index.vue';
import NationalHolidaySellers30 from './NationalHolidaySellers30/index.vue';
import NationalHolidaySellers50 from './NationalHolidaySellers50/index.vue';
import TopRepublicationB2c from './TopRepublicationB2c/index.vue';
import TopRepublicationSmb from './TopRepublicationSmb/index.vue';
import CarForZSU from './CarForZSU/index.vue';
import CossacksDay from './CossacksDay/index.vue';
import February29 from './February29/index.vue';
import FiveCarsWithVIN from './FiveCarsWithVIN/index.vue';
import CabinetSlidesBlockId111 from './CabinetSlidesBlockId111/index.vue';
import CabinetSlidesBlockId117 from './CabinetSlidesBlockId117/index.vue';
import CabinetSlidesBlockId129 from './CabinetSlidesBlockId129/index.vue';
import CabinetSlidesBlockId130 from './CabinetSlidesBlockId130/index.vue';
import CabinetSlidesBlockId132 from './CabinetSlidesBlockId132/index.vue';
import CabinetSlidesBlockId135 from './CabinetSlidesBlockId135/index.vue';
import CabinetSlidesBlockId136 from './CabinetSlidesBlockId136/index.vue';
import CabinetSlidesBlockId137 from './CabinetSlidesBlockId137/index.vue';
import CabinetSlidesBlockId138 from './CabinetSlidesBlockId138/index.vue';
import CabinetSlidesBlockId139 from './CabinetSlidesBlockId139/index.vue';
import CabinetSlidesBlockId140 from './CabinetSlidesBlockId140/index.vue';
import CabinetSlidesBlockId145 from './CabinetSlidesBlockId145/index.vue';
import CabinetSlidesBlockId146 from './CabinetSlidesBlockId146/index.vue';
import CabinetSlidesBlockId147 from './CabinetSlidesBlockId147/index.vue';
import CabinetSlidesBlockId149 from './CabinetSlidesBlockId149/index.vue';
import CabinetSlidesBlockId152 from './CabinetSlidesBlockId152/index.vue';
import CabinetSlidesBlockId153 from './CabinetSlidesBlockId153/index.vue';
import CabinetSlidesBlockId154 from './CabinetSlidesBlockId154/index.vue';
import CabinetSlidesBlockId155 from './CabinetSlidesBlockId155/index.vue';
import CabinetSlidesBlockId157 from './CabinetSlidesBlockId157/index.vue';
import CabinetSlidesBlockId158 from './CabinetSlidesBlockId158/index.vue';
import CabinetSlidesBlockId159 from './CabinetSlidesBlockId159/index.vue';
import CabinetSlidesBlockId160 from './CabinetSlidesBlockId160/index.vue';
import CabinetSlidesBlockId161 from './CabinetSlidesBlockId161/index.vue';
import CabinetSlidesBlockId162 from './CabinetSlidesBlockId162/index.vue';
import CabinetSlidesBlockId163 from './CabinetSlidesBlockId163/index.vue';
import CabinetSlidesBlockId164 from './CabinetSlidesBlockId164/index.vue';
import CabinetSlidesBlockId165 from './CabinetSlidesBlockId165/index.vue';
import CabinetSlidesBlockId166 from './CabinetSlidesBlockId166/index.vue';
import CabinetSlidesBlockId167 from './CabinetSlidesBlockId167/index.vue';
import CabinetSlidesBlockId170 from './CabinetSlidesBlockId170/index.vue';
import CabinetSlidesBlockId171 from './CabinetSlidesBlockId171/index.vue';
import CabinetSlidesBlockId172 from './CabinetSlidesBlockId172/index.vue';
import CabinetSlidesBlockId173 from './CabinetSlidesBlockId173/index.vue';
import CabinetSlidesBlockId174 from './CabinetSlidesBlockId174/index.vue';
import CabinetSlidesBlockId177 from './CabinetSlidesBlockId177/index.vue';
import CabinetSlidesBlockId178 from './CabinetSlidesBlockId178/index.vue';
import CabinetSlidesBlockId180 from './CabinetSlidesBlockId180/index.vue';
import CabinetSlidesBlockId181 from './CabinetSlidesBlockId181/index.vue';
import CabinetSlidesBlockId182 from './CabinetSlidesBlockId182/index.vue';
import CabinetSlidesBlockId183 from './CabinetSlidesBlockId183/index.vue';
import CabinetSlidesBlockId184 from './CabinetSlidesBlockId184/index.vue';
import CabinetSlidesBlockId185 from './CabinetSlidesBlockId185/index.vue';
import CabinetSlidesBlockId187 from './CabinetSlidesBlockId187/index.vue';
import CabinetSlidesBlockId188 from './CabinetSlidesBlockId188/index.vue';
import CabinetSlidesBlockId189 from './CabinetSlidesBlockId189/index.vue';
import CabinetSlidesBlockId190 from './CabinetSlidesBlockId190/index.vue';
import CabinetSlidesBlockId191 from './CabinetSlidesBlockId191/index.vue';
import CabinetSlidesBlockId194 from './CabinetSlidesBlockId194/index.vue';
import CabinetSlidesBlockId195 from './CabinetSlidesBlockId195/index.vue';
import CabinetSlidesBlockId196 from './CabinetSlidesBlockId196/index.vue';
import CabinetSlidesBlockId197 from './CabinetSlidesBlockId197/index.vue';
import CabinetSlidesBlockId199 from './CabinetSlidesBlockId199/index.vue';
import CabinetSlidesBlockId200 from './CabinetSlidesBlockId200/index.vue';
import slonDataLogger from '../../../../mixins/slonDataLogger';
import { AB_TEST_ID } from '../../../../../constants/abTest';

const { set } = require('../../../../helpers/cookie');
const benefitsDataLogger = require('../../../../mixins/benefitsDataLogger');

export default {
  mixins: [benefitsDataLogger, slonDataLogger],
  name: 'PromoRotator',
  data() {
    return {
      index: 0,
      isActive: false,
      touch: { x: 0 },
      interval: null,
      slideList: [
        'OrderPackage',
        'PromoBRK',
        'PromoBRK2',
        'PromoAPP',
        'BannerBoldness',
        'PromoFreePublication',
        'PartnerProgram',
        'RegionPublishFree',
        'FrontlineRegions',
        'BenefitTops',
        'SafeInternet',
        'CarOwner',
        'WebinarForBusiness',
        'VerificationCenter',
        'ElectricCars',
        'NAInsights',
        'BirthdayAutoRia',
        'IndependenceDay',
        'CabinetDriver',
        'AdvertisingReit',
        'Skofka',
        'PromoAppBuyers',
        'PromoAppSellers',
        'MotoringDay',
        'Autoleader',
        'BlackFridayBuyer',
        'BlackFridayDay1Sellers',
        'BlackFridayDay2Sellers',
        'BlackFridayDay3Sellers',
        'NewTeaser',
        'MainWave',
        'ResultsYear',
        'Tops23',
        'TopsC2C',
        'TopsSMB',
        'TopsB2C1',
        'TopsB2C2',
        'Tops28',
        'PromoFinancing',
        'NationalHolidayBuyers',
        'NationalHolidaySellers30',
        'NationalHolidaySellers50',
        'TopRepublicationB2c',
        'TopRepublicationSmb',
        'CarForZSU',
        'CossacksDay',
        'February29',
        'FiveCarsWithVIN',
        'CabinetSlidesBlockId100',
        'CabinetSlidesBlockId101',
        'CabinetSlidesBlockId104',
        'CabinetSlidesBlockId107',
        'CabinetSlidesBlockId111',
        'CabinetSlidesBlockId112',
        'CabinetSlidesBlockId113',
        'CabinetSlidesBlockId114',
        'CabinetSlidesBlockId116',
        'CabinetSlidesBlockId117',
        'CabinetSlidesBlockId120',
        'CabinetSlidesBlockId122',
        'CabinetSlidesBlockId123',
        'CabinetSlidesBlockId125',
        'CabinetSlidesBlockId126',
        'CabinetSlidesBlockId128',
        'CabinetSlidesBlockId129',
        'CabinetSlidesBlockId130',
        'CabinetSlidesBlockId132',
        'CabinetSlidesBlockId135',
        'CabinetSlidesBlockId136',
        'CabinetSlidesBlockId137',
        'CabinetSlidesBlockId138',
        'CabinetSlidesBlockId139',
        'CabinetSlidesBlockId140',
        'CabinetSlidesBlockId145',
        'CabinetSlidesBlockId146',
        'CabinetSlidesBlockId147',
        'CabinetSlidesBlockId149',
        'CabinetSlidesBlockId152',
        'CabinetSlidesBlockId153',
        'CabinetSlidesBlockId154',
        'CabinetSlidesBlockId155',
        'CabinetSlidesBlockId157',
        'CabinetSlidesBlockId158',
        'CabinetSlidesBlockId159',
        'CabinetSlidesBlockId160',
        'CabinetSlidesBlockId161',
        'CabinetSlidesBlockId162',
        'CabinetSlidesBlockId163',
        'CabinetSlidesBlockId164',
        'CabinetSlidesBlockId165',
        'CabinetSlidesBlockId166',
        'CabinetSlidesBlockId167',
        'CabinetSlidesBlockId170',
        'CabinetSlidesBlockId171',
        'CabinetSlidesBlockId172',
        'CabinetSlidesBlockId173',
        'CabinetSlidesBlockId174',
        'CabinetSlidesBlockId177',
        'CabinetSlidesBlockId178',
        'CabinetSlidesBlockId180',
        'CabinetSlidesBlockId181',
        'CabinetSlidesBlockId182',
        'CabinetSlidesBlockId183',
        'CabinetSlidesBlockId184',
        'CabinetSlidesBlockId185',
        'CabinetSlidesBlockId187',
        'CabinetSlidesBlockId188',
        'CabinetSlidesBlockId189',
        'CabinetSlidesBlockId190',
        'CabinetSlidesBlockId191',
        'CabinetSlidesBlockId194',
        'CabinetSlidesBlockId195',
        'CabinetSlidesBlockId196',
        'CabinetSlidesBlockId197',
        'CabinetSlidesBlockId199',
        'CabinetSlidesBlockId200',
      ],
    };
  },
  components: {
    OrderPackage,
    PromoBRK,
    PromoBRK2,
    PromoAPP,
    BannerBoldness,
    PromoFreePublication,
    RegionPublishFree,
    FrontlineRegions,
    BenefitTops,
    CarOwner,
    WebinarForBusiness,
    VerificationCenter,
    ElectricCars,
    NAInsights,
    BirthdayAutoRia,
    IndependenceDay,
    CabinetDriver,
    Skofka,
    MotoringDay,
    Autoleader,
    BlackFridayBuyer,
    BlackFridayDay1Sellers,
    BlackFridayDay2Sellers,
    BlackFridayDay3Sellers,
    NewTeaser,
    ResultsYear,
    Tops23,
    TopsC2C,
    TopsSMB,
    TopsB2C1,
    TopsB2C2,
    Tops28,
    PromoFinancing,
    NationalHolidayBuyers,
    NationalHolidaySellers30,
    NationalHolidaySellers50,
    TopRepublicationB2c,
    TopRepublicationSmb,
    CarForZSU,
    CossacksDay,
    February29,
    FiveCarsWithVIN,
    CabinetSlidesBlockId111,
    CabinetSlidesBlockId117,
    CabinetSlidesBlockId129,
    CabinetSlidesBlockId130,
    CabinetSlidesBlockId132,
    CabinetSlidesBlockId135,
    CabinetSlidesBlockId136,
    CabinetSlidesBlockId137,
    CabinetSlidesBlockId138,
    CabinetSlidesBlockId139,
    CabinetSlidesBlockId140,
    CabinetSlidesBlockId145,
    CabinetSlidesBlockId146,
    CabinetSlidesBlockId147,
    CabinetSlidesBlockId149,
    CabinetSlidesBlockId152,
    CabinetSlidesBlockId153,
    CabinetSlidesBlockId154,
    CabinetSlidesBlockId155,
    CabinetSlidesBlockId157,
    CabinetSlidesBlockId158,
    CabinetSlidesBlockId159,
    CabinetSlidesBlockId160,
    CabinetSlidesBlockId161,
    CabinetSlidesBlockId162,
    CabinetSlidesBlockId163,
    CabinetSlidesBlockId164,
    CabinetSlidesBlockId165,
    CabinetSlidesBlockId166,
    CabinetSlidesBlockId167,
    CabinetSlidesBlockId170,
    CabinetSlidesBlockId171,
    CabinetSlidesBlockId172,
    CabinetSlidesBlockId173,
    CabinetSlidesBlockId174,
    CabinetSlidesBlockId177,
    CabinetSlidesBlockId178,
    CabinetSlidesBlockId180,
    CabinetSlidesBlockId181,
    CabinetSlidesBlockId182,
    CabinetSlidesBlockId183,
    CabinetSlidesBlockId184,
    CabinetSlidesBlockId185,
    CabinetSlidesBlockId187,
    CabinetSlidesBlockId188,
    CabinetSlidesBlockId189,
    CabinetSlidesBlockId190,
    CabinetSlidesBlockId191,
    CabinetSlidesBlockId194,
    CabinetSlidesBlockId195,
    CabinetSlidesBlockId196,
    CabinetSlidesBlockId197,
    CabinetSlidesBlockId199,
    CabinetSlidesBlockId200,
  },
  methods: {
    ...mapActions({
      fetchSliders: 'infoBlock/cabinet/fetchSliders',
    }),
    async closePromoRotator() {
      const { name: cookieName, expires } = this.filteredSliders[this.index].cookie;
      set(cookieName, 1, { expires });

      if (cookieName === 'slide_Discount') {
        this.sendEventToSlonik({ event_id: 755, from_type: 2, current_point_action: 'PromoRotator[closePromoRotator]' });
      }

      await this.fetchSliders();

      if (this.index < this.filteredSliders.length - 1) {
        this.next();
      } else {
        this.prev();
      }

      this.gaEvent('Cabinet', 'rotator_click_close', 'PromoRotator');
    },
    prev() {
      if (this.index !== 0) {
        this.index -= 1;
      } else {
        this.index = this.filteredSliders.length - 1;
      }
      this.autoNext();
      this.gaEvent('Cabinet', 'rotator_click_prev', 'PromoRotator');
    },
    next() {
      if (this.index < this.filteredSliders.length - 1) {
        this.index += 1;
      } else {
        this.index = 0;
      }
      this.autoNext();
      this.gaEvent('Cabinet', 'rotator_click_next', 'PromoRotator');
    },
    goto(index) {
      this.index = index;
      this.autoNext();
    },
    touchstart(event) {
      const { changedTouches: { 0: { pageX: x } = {} } = {} } = event;
      this.touch.x = x;
    },
    touchend(event) {
      const { changedTouches: { 0: { pageX: x } = {} } = {} } = event;
      if (x - this.touch.x > 50) {
        this.prev();
        this.gaEvent('Cabinet', 'rotator_swipe_prev', 'PromoRotator');
      }
      if (this.touch.x - x > 50) {
        this.next();
        this.gaEvent('Cabinet', 'rotator_swipe_next', 'PromoRotator');
      }
      this.autoNext();
    },
    autoNext() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.next();
      }, 6000);
    },
    loggerPromoRotator(slideData, event) {
      const link = event?.target?.href;
      if (link) {
        this._slonik({
          event_id: 1467,
          current_point_action: slideData.name,
          current_url: link,
          previous_url: this.clearAutoRiaUrl(window?.location?.pathname),
        });
      }
    },
  },
  async init({ store, context }) {
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }

    await Promise.all([
      store.dispatch('others/analytic/fetchABtestVariants', { testName: AB_TEST_ID.FAST_PAY_BTN }),
      store.dispatch('cabinet/advertisements/fetch', cookies),
      store.dispatch('infoBlock/cabinet/fetchSliders', cookies),
    ]);
  },
  mounted() {
    this.autoNext();
  },
  computed: {
    ...mapGetters({
      slidersData: 'infoBlock/cabinet/slidersData',
    }),
    filteredSliders() {
      return this.slidersData.filter((slider) => (!slider?.params // no params
        || slider?.params?.device === 'all' // for all devices
        || (this.isDesktop && slider?.params?.device === 'desktop') // for desktops
        || (!this.isDesktop && slider?.params?.device === 'mobile')) // for mobiles
      && this.slideList.includes(slider?.name)); // з бека прилітають імена лайдів яких у нас немає
    },
    showIndicator() {
      return this.filteredSliders.length > 1;
    },
    showRotator() {
      return Boolean(this.filteredSliders.length);
    },
  },
};
